
header{ 
	position:relative; 
	width:100%; 
/* 	height:185px;  */
	z-index:1;  
	margin-bottom:20px;
	text-align:center; 
} 
header img{ 
	position:relative; 
	display:block; 
	margin:10px auto; 
} 
header h2{
	font-family: 'Oswald', sans-serif;
	background-color:#28377D; 
	color:white; 
	text-transform:uppercase; 
	text-align:center;
	display:block; 
	margin:0px auto; 
	font-size:1.75rem;
	padding-top:20px;
	padding-bottom:20px;
	width:90%;
	top:40px;
	border-radius:20px; 
	  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
	